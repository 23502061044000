(function ($) {
    $(document).ready(function () {
        var $navDays = $('[data-js-schedule-nav-tab]');
        $navDays.each(function () {
            var $this = $(this),
                attr = $this.attr('data-js-schedule-nav-tab');

            $this.click(function (e) {
                $('[data-js-schedule-tab]')
                    .removeClass('is-active')
                    .filter('[data-js-schedule-tab="'+attr+'"]')
                    .addClass('is-active');

                $navDays.removeClass('is-active');
                $this.addClass('is-active');
                e.preventDefault();
            });
        });
    });
}(jQuery))