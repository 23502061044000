(function ($) {
    $(document).ready(function() {

        /**
         * Deferr loading iframes until page is loaded
         */
        $('iframe').each(function () {
            var $this = $(this),
                src = $this.attr('data-src');

            if (src) {
                $this[0].src = src;
            }
        });

    });
}(jQuery));