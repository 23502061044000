(function ($) { // iief = Immediately-Invoked Function Expression, mainly useful to limit scope
    $(function() { // Shorthand for $( document ).ready()

        // change menu active states on scroll position

        function onScroll(event){
            var scrollPosition = $(document).scrollTop(), // distance to top from matched element
                $links = $('[data-js-scroll-nav] a').not('.menu__links--language'), // get all links in the nav
                windowHeight = $(window).height();

            $links.each(function () {
                var currentLink = $(this);      // extract href of each link
                var refElement = $(currentLink.attr('href'));
                var refElemPos = refElement.position();
                // if (thisPos higher than scrollPos && )
                if (refElemPos.top <= scrollPosition + (windowHeight / 4) && refElemPos.top + refElement.height() > scrollPosition + (windowHeight / 4)) {
                    $links.removeClass("active");
                    currentLink.addClass("active");
                    return false;
                } else {
                    currentLink.removeClass("active");
                }
            });
        }

        // preload gifs for hover effect
        function preloader() {
            images = new Array();

            // load all gifs from folder into array
            $.ajax({
                url: "../../img/animated/",
                success: function(data){
                    $(data).find("a:contains(.gif)").each(function(){
                        console.log("$(this).attr('href'): " + $(this).attr('href'));
                        // push all hrefs of gifs to array
                        images[images.length] = $(this).attr("href");

                        console.log("images.length(): " + images.length);

                        // start preloading
                        for(var i = 0; i < images.length; i++)
                        {
                            // create new images
                            new Image().src = images[i];

                        }
                    });
                }
            });
        }


        $(document).on("scroll", onScroll);

        // dont select menu btn – otherwise default (show/hide menu) are prevented
        $('a[href^="#"]:not(".menu_btn")').not('[data-js-schedule-nav-tab]').on('click', function (e) {
            e.preventDefault();
            //unbind handler
            $(document).off("scroll");

            $('.menu__items a').each(function () {
                $(this).removeClass('active');
            })
            $(this).addClass('active');

            var target = this.hash;     // hash = for this url: foo.com/index.html#foo the hash is #foo
            $target = $(target);        // get object with selector $('#foo')
            $('html, body').stop().animate({
                'scrollTop': $target.offset().top - 100
            }, 500, 'swing', function () {
                window.location.hash = target;
                $(document).on("scroll", onScroll);
            });
        });


        // adjust myCanvas size to window
        var windowHeight = $(window).height();
        // console.log("windowHeight: " + windowHeight);
        $('#myCanvas').css('height', windowHeight);

        // set today
        var now = moment();
        // console.log("now: " + now.format("dddd, MMMM Do YYYY, h:mm:ss a"));
        var eventDate = moment('2015-09-25');
        // console.log("eventDate: " + eventDate.format("dddd, MMMM Do YYYY, h:mm:ss a"));
        var diffInDays = -now.diff(eventDate, 'days'); // 1 day
        console.log("diffInDays: " + diffInDays);

        // put difference on page
        if (Designathon && Designathon.lang === 'de') {
            $('.js-days-to-event').html('Noch ' + diffInDays + '<br> Tage');
        } else {
            $('.js-days-to-event').html(diffInDays + ' days<br>left');

        }

        // start preloader
        // preloader();

    });
}(jQuery));